.Logo{
    max-width: 120px;
    display:grid;
    grid-template-columns: 30% 70%;
    cursor: pointer;
}


.Logo-icon {
    /*animation: Logo-logo-spin infinite 20s linear;*/
    max-width:100%;
}

.Logo-text {
    max-width:100%;
}

@keyframes Logo-logo-spin {
    from {
        transform: rotate(0deg);
    }
    to {
        transform: rotate(360deg);
    }
}
