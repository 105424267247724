.Page-HomePage-Mission{
    margin:200px auto;
    max-width:var(--main-window-max-width);
    width:90%;
    display: grid;
    grid-template-columns: 2fr 0.5fr 2fr;
    font-family: var(--main-font-family);
}

.Page-HomePage-Mission-image{
    justify-self: right;
    grid-column: 2 / -1;
    grid-row: 1 / 4;
    width:90%;
}

.Page-HomePage-Mission-heading{
    justify-self: left;
    text-align: left;
    grid-column: 1 / 3;
    grid-row: 1 / 2;
    color:var(--main-title-color);
    font-weight: var(--strong-font-weight);
    font-size: var(--large-title-font-size);
    margin: 0;
}

.Page-HomePage-Mission-paragraph{
    justify-self: left;
    text-align: left;
    grid-column: 1 / 2;
    grid-row: 2 / 3;
    font-size: 20px;
    margin: 0 0 50px 0;
}

.Page-HomePage-Mission-contact{
    justify-self: left;
    text-align: left;
    grid-column: 1 / 2;
    grid-row: 3 / 4;
}


@media only screen and (max-width: 990px) {
    .Page-HomePage-Mission{
        margin: auto;
        grid-template-columns: auto;
        grid-gap: 10px;
    }

    .Page-HomePage-Mission-image{
        grid-column: 1 / 2;
        grid-row: 4 / 5;
        margin: 30px auto;
    }

    .Page-HomePage-Mission-heading{
        grid-column: 1 / 2;
        grid-row: 1 / 2;
        font-size: 30px;
    }

    .Page-HomePage-Mission-paragraph{
        grid-column: 1 / 2;
        grid-row: 2 / 3;
    }

    .Page-HomePage-Mission-contact{
        grid-column: 1 / 2;
        grid-row: 3 / 4;
    }
}
