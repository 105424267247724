.Cover{
    display:grid;
    grid-template-rows: auto minmax(auto, 100px) minmax(auto, 40px) auto;
    grid-template-columns: 1fr auto 1fr;
}

.Cover-image-group{
    grid-row: 1 / 5;
    grid-column: 1 / 4;
    display: grid;
    grid-template-columns: 1fr;
}

.Cover-image{
    grid-row: 1 / 2;
    grid-column: 1 / 2;
    max-height: 480px;
    width:100%;
    height:100%;
    object-fit: cover;
    object-position: 30% 50%;
}

.Cover-title{
    z-index: 1;
    grid-row: 2 / 3;
    grid-column: 2 / 3;
    color: var(--bright-text-color);
    font-family: var(--main-font-family);
    font-size: 60px;
    text-shadow:5px 5px 5px black;
}

.Cover-subTitle{
    z-index: 1;
    grid-row: 3 / 4;
    grid-column: 2 / 3;
    color: var(--bright-text-color);
    font-family: var(--secondary-font-family);
    font-size: 25px;
}


/* appear - on page load */
.fade-appear {
    opacity: 0;
}

.fade-appear-active {
    opacity: 1;
    transition: opacity 300ms linear;
}

.fade-enter {
    opacity: 0;
}

.fade-enter-active{
    opacity: 1;
    transition: opacity 300ms linear 300ms;
}

.fade-exit {
    opacity: 1;
}

.fade-exit-active {
    opacity: 0;
    transition: opacity 300ms linear 300ms;
}

@media only screen and (max-width: 900px) {
    .Cover-title{
        font-size: 36px;
    }

    .Cover-image{
        max-height: 350px;
    }

    .Cover-subTitle{
        font-size: 18px;
    }
}

