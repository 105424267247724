.Page-HomePage-WhyUsBox {
    max-width: var(--main-window-max-width);
    width: 95%;
    min-height: 300px;
    margin:65px auto;
    display: grid;
    grid-template-columns: 30% 70%;
    border-radius: 10px;
    box-shadow: 0 5px 14px 1px hsla(0, 0%, 76.7%, 0.49);
    overflow: hidden;
}

.Page-HomePage-WhyUsBox-img{
     grid-column: 1 / 2;
     grid-row: 1 / 2;
     width:100%;
     height:100%;
     object-fit: cover;
 }

.Page-HomePage-WhyUsBox-title{
    grid-column: 1 / 2;
    grid-row: 1 / 2;
    align-self: center;
    font-family: var(--main-font-family);
    color: var(--bright-text-color);
    font-size: var(--large-title-font-size);
    font-weight: var(--strong-font-weight);
}

.Page-HomePage-WhyUsBox-content{
    grid-column: 2 / 3;
    grid-row: 1 / 2;
    padding: 35px;
    font-family: var(--main-font-family);
    color: #242424;
    font-size: 18px;
    line-height: 27px;
    font-weight: var(--secoundary-font-weight);
    text-align: left;
    margin:auto;
    white-space: pre-wrap;
}

@media only screen and (max-width: 990px) {
    .Page-HomePage-WhyUsBox {
        grid-template-columns: 1fr;
        grid-template-rows: 150px auto;
        width: 85vw;
    }
    .Page-HomePage-WhyUsBox-content {
        grid-column: 1 / 2;
        grid-row: 2 / 3;
    }
}
