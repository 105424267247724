.NavBar{
    width:100%;
    background-color: var(--secoundary-background-color);
}
.NavBar-background{
    margin:auto;
    width:100%;
    padding:20px;
    max-width: var(--main-window-max-width);
    max-height: 76px;
    display: grid;
    grid-template-columns: 1fr minmax(100px, auto) auto;
    z-index: 1;
    background-color: var(--secoundary-background-color);
}

.NavBar-items{
    max-width: 650px;
    grid-column: 3 / 4;
    display: grid;
    grid-template-columns:repeat(6,minmax(100px, auto));
    justify-content: space-evenly;
}

.NavBar-link{
    height: 100%;
    background: none;
    border: none;
}

.NavBar-link:hover{
    cursor: pointer;
    background: var(--color-secoundary-var1);
}

.NavBar-link:focus { outline: none; }

.NavBar-selected{
    color:  var(--color-main);
    font-weight: bold;
}

.NavBar-hamburger{
    display: none;
    justify-self: end;
    margin: 20px;
    justify-content: center;
}

.NavBar-hamburger > img{
    grid-column: 1 /2;
    grid-row: 1 /2;
    transition-duration: 1s;
}

.NavBar-hamburger img:first-child{
    transform: rotateX(0deg);
}

.NavBar-hamburger img:nth-child(2){
    padding-left: 7px;
    transform: rotateX(90deg) translateY(50px);
}


.NavBar-hamburgerOpen img:first-child{
    transform: rotateX(90deg) translateY(-50px);
}

.NavBar-hamburgerOpen img:nth-child(2){
    transform: rotateX(0);
}

@media only screen and (max-width: 900px) {
    .NavBar-background {
        grid-template-rows: 100%;
        padding: 0;
    }

    .Logo{
        margin: 20px;
    }
    .NavBar-hamburger{
        display: grid;
    }

    .NavBar-items{
        z-index: -1;
        position: relative;
        grid-column: 1 / 3;
        width:100vw;
        max-width: none;

        top: -1000px;
        grid-template-columns: auto;
        background: var(--secoundary-background-color);
        border-top: 1px #bbb solid;
        border-bottom: 3px var(--color-main) solid;
        transition-duration: 1s;
    }
    .NavBar-link{
        height: 60px;
        width: 100vw;
        cursor: pointer;
        font-size: 20px;
        padding:0;
    }
    .NavBar-link:hover{
        background: var(--main-background-color);
    }

    .NavBar-menuOpen{
        transform: translateY(1000px);
    }
}
