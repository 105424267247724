.Page-ServicesPage-ServiceBox {
    position: relative;
    width: 210px;
    min-height: 430px;
    border: 1px var(--main-border-color) solid;
    border-radius: 2px;
    margin: 20px 18px;
    padding: 40px 25px 35px 25px;
    text-align: left;
    font-family: var(--main-font-family);
}

.Page-ServicesPage-ServiceBox:hover {
    border-color: var(--color-main);
    cursor: pointer;
}


.Page-ServicesPage-ServiceBox-img {
    position: absolute;
    left: 15px;
    top: -30px;
    right: auto;
    bottom: auto;
    width: 65px;
}

.Page-ServicesPage-ServiceBox-title{
    font-weight: var(--strong-font-weight);
    font-size: var(--subtitle-font-size);
    color: var(--main-text-color);
}

.Page-ServicesPage-ServiceBox-content{
    font-weight: var(--secoundary-font-weight);
    font-size: 18px;
    line-height: 27px;
    color: var(--main-text-color);
}

.Page-ServicesPage-ServiceBox-readMore{
    position: absolute;
    left: 25px;
    top: auto;
    right: auto;
    bottom: 15px;
    font-weight: var(--main-font-weight);
    font-size: var(--text-font-size);
    color: var(--color-main);
}

@media only screen and (max-width: 990px) {
    .Page-ServicesPage-ServiceBox {
        width: auto;
        max-width: 300px;
        min-height: auto;
    }
}

@media only screen and (max-width: 500px) {
    .Page-ServicesPage-ServiceBox {
        max-width: 90vw;
    }
}
