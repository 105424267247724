.Page-ValuesPage{
    background-color: var(--main-background-color);
    display:grid;
    padding:0 30px;
    background-origin: padding-box;
}

.Pages-ValuesPage-value{
    max-width: 650px;
    margin: 40px auto;
    display: grid;
}

.Pages-ValuesPage-number{
    border-radius: 100%;
    border: 1px var(--color-secoundary) solid;
    color: var(--color-secoundary);
    width: 50px;
    height:50px;
    line-height: 50px;
    font-size: 24px;
    font-weight: var(--secoundary-font-weight);
    font-family: var(--secondary-font-family);
    margin: 15px 0;
}

.Pages-ValuesPage-title{
    text-align: left;
    font-family: var(--main-font-family);
    color: var(--secoundary-text-color);
    font-size: 31px;
    font-weight: var(--main-font-weight);
    margin-bottom: 10px;
}

.Pages-ValuesPage-content{
    text-align: left;
    font-family: var(--main-font-family);
    color: var(--secoundary-text-color);
    font-size: 18px;
}
