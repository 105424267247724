.Page-ServicesPage-Services{
    margin: 60px auto;
    background-image: var(--gradient-background);
    width:100%;
}

.Page-ServicesPage-Services-row{
    max-width: var(--main-window-max-width);
    margin:auto;
    display: flex;
    justify-content: center;
}

@media only screen and (max-width: 990px) {
    .Page-ServicesPage-Services-row{
        flex-wrap: wrap;
    }
}
