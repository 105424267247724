.ServicesDescriptionPage-back{
    width: 100%;
    text-align: left;
    background-color: #e3edf0;
    margin-bottom: 30px;
    padding: 10vh 0;
}

.ServicesDescriptionPage-backLink{
    max-width: var(--main-window-max-width);
    margin:  14px auto;
    color: var(--main-text-color);
    font-family: var(--main-font-family);
    font-size:15px;
    font-weight: var(--main-font-weight);
    padding-left: 20px;
    cursor: pointer;
}

.ServicesDescriptionPage-backTitle{
    max-width: var(--main-window-max-width);
    margin:  14px auto;
    color: var(--main-text-color);
    font-family: var(--main-font-family);
    font-size:44px;
    font-weight: 500;
    padding-left: 20px;
}

.ServicesDescriptionPage-content{
    max-width: var(--main-window-max-width);
    margin:auto;
    overflow: hidden;
}

.ServicesDescriptionPage > div > h1{
    max-width: 925px;
    margin: auto;
    text-align: left;
    font-family: var(--main-font-family);
    font-weight: 600;
    color: #333333;
    line-height: 40px;
    font-size: 29px;
    padding: 0 20px;
}

.ServicesDescriptionPage > div > p{
    max-width: 925px;
    margin: auto auto 50px auto;
    text-align: left;
    font-family: var(--main-font-family);
    font-weight: var(--main-font-family);
    color: #333333;
    line-height: 40px;
    font-size: 21px;
    white-space: pre-wrap;
    padding: 0 20px;
}

@media only screen and (max-width: 500px) {
    .ServicesDescriptionPage > div > p{
        font-size: 19px;
        line-height: 25px;
    }



    /*.ServicesDescriptionPage-back{*/
    /*    margin-bottom: 150px;*/
    /*    padding: 10vh;*/
    /*}*/
    .ServicesDescriptionPage-backTitle{
        font-size:36px;
    }
}
