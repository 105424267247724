.Page-HomePage-titles {
    max-width: var(--main-window-max-width);
    margin:auto;
    font-family: var(--main-font-family);
    font-weight: var(--main-font-weight);
    font-size: var(--large-title-font-size);
    line-height: 55px;
    color: var(--main-title-color);
    text-align: left;
    padding-left: 30px;
}

@media only screen and (max-width: 500px) {
    .Page-HomePage-titles {
        max-width: 80%;
        font-size: 31px;
        line-height: 35px;
        padding-left: 0;
    }
}
