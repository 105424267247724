.Page-TeamPage{
    max-width: var(--main-window-max-width);
    margin:50px auto;
    font-family: var(--main-font-family);
    color : var(--main-text-color);
}

.Page-TeamPage-title{
    font-size: var(--large-title-font-size);
    font-weight: var(--main-font-weight);
}
.Page-TeamPage-members{
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(350px, 1fr));
}

@media only screen and (max-width: 900px) {
    .Page-TeamPage-members{
        grid-template-columns: repeat(auto-fill, minmax(250px, 1fr));
        justify-items: center;
    }
}


@media only screen and (max-width: 500px) {
    .Page-TeamPage-members{
        grid-template-columns: repeat(auto-fill, minmax(176px, 1fr));
    }
}
