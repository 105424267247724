:root {
    --main-text-color: #2c2b2c;
    --secoundary-text-color: #616161;
    --bright-text-color: #ffffff;
    --main-title-color: #273152;
    --main-background-color:#F8F7FF;
    --secoundary-background-color:#FFF;
    --gradient-background:linear-gradient(180deg, #fff 8%, #f8f7ff 16%);
    --main-border-color:#d7d8ea;
    --secodary-border-color: #994076;
    --main-window-max-width: 1300px;
    --main-font-family: 'Muli', sans-serif;
    --secondary-font-family: 'IBM Plex Mono', sans-serif;
    --main-font-weight: 400;
    --secoundary-font-weight: 200;
    --strong-font-weight: 700;
    --title-font-size:38px;
    --medium-title-font-size:43px;
    --large-title-font-size:50px;
    --subtitle-font-size:24px;
    --large-text-font-size:19px;
    --text-font-size:15px;
    --color-main:#994076;
    --color-var1:#bd5a96;
    --color-var2:#792d5b;
    --color-secoundary:#6cc2ae;
    --color-secoundary-var1:rgba(111,193,194,0.1);
}

.App {
    text-align: center;
    display: grid;
    grid-gap: 3px 0;
    grid-template-areas:
      ". h ."
      "c c c"
      "p p p"
      "b b b"
      ". f .";
    font-family: var(--main-font-family);
}

.App {
    grid-template-columns: 1fr minmax(auto, var(--main-window-max-width)) 1fr;
    grid-template-rows: 76px;
}

.NavBar{
    grid-area: h;
    position:fixed;
    z-index: 1;
}

.Cover{
    grid-area: c;
    height: 480px;
    z-index: 0;
}

.Page{
    grid-area: p;
}

.BottomBar{
    grid-area: b;
    height: 200px;
}

.Footer{
    grid-area: f;
}


._strong{
    font-weight: var(--strong-font-weight);
}

@media only screen and (max-width: 900px) {
    .Cover{
        height: 350px;
    }
}

@media only screen and (max-width: 500px) {
    .Cover{
        height: 256px;
    }
}
