.Footer{
    display: grid;
    grid-template-columns: auto 1fr auto;
    grid-template-areas:
            "l n m"
            ". n ."
            "c n s";
    padding: 20px;
}

.Footer-logo{
    grid-area: l;
    max-width: 129px;
}

.Footer-contacts{
    grid-area: m;
}

.Footer-copyrights{
    grid-area: c;
    font-size: 17px;
}

.Footer-SocialBar{
    grid-area: s;
    display:grid;
}

.Footer-Nav{
    grid-area: n;
    display: grid;
    grid-template-columns: 1fr;
}

.Footer-link{
    background: none;
    border: none;
    padding: 10px;
    cursor: pointer;
    font-family: var(--main-font-family);
    font-size: var(--large-text-font-size);
}

.Footer-link:focus { outline: none; }

@media only screen and (max-width: 500px) {
    .Footer{
        display: grid;
        grid-template-columns: auto;
        grid-gap: 10px;
        grid-template-areas:
                "l"
                "c"
                "n"
                "m"
                "s";
        padding: 20px;
        justify-items: left;
        text-align: left;
    }
    .Footer-link{
        text-align: left;
    }
}


