.Page-CareersPage{
    display:grid;
    background-color: var(--main-background-color);
    padding: 30px;
}

.Page-CareersPage-pageTitle{
    margin: 50px;
    font-family: var(--main-font-family);
    font-weight: var(--main-font-weight);
    font-size: var(--title-font-size);
    color: var(--main-text-color);
}

.Page-CareersPage-position{
    width:80%;
    max-width: var(--main-window-max-width);
    background-color: var(--secoundary-background-color);
    margin: 20px auto;
    padding:25px;
    height: 100px;
    border: 1px var(--main-border-color) solid;
    border-radius: 10px;
    position: relative;
    cursor: pointer;
}

.Page-CareersPage-position:hover{
    border-color: var(--secodary-border-color);
    cursor: pointer;
}

.Page-CareersPage-title{
    text-align: left;
    margin:0;
    color: var(--main-text-color);
    font-family: var(--main-font-family);
    font-weight: var(--strong-font-weight);
}

.Page-CareersPage-applyButton{
    position: absolute;
    left: 25px;
    bottom: 0;
    text-align: left;
    font-weight: var(--main-font-weight);
    font-family: var(--main-font-family);
    font-size: var(--text-font-size);
    color: var(--color-main);
}
