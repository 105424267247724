.ContactButton{
    background: var(--color-var2);
    color: var(--bright-text-color);
    border: none;
    border-radius: 8px;
    width:200px;
    height: 50px;
    font-family: var(--main-font-family);
    font-size: 18px;
}

.ContactButton:hover{
    cursor: pointer;
    background: var(--color-var1);
}
