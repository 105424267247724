.BottomBar{
    padding:50px;
    background-color: var(--color-main);
    color: var(--bright-text-color);
}

.BottomBar-title{
    font-size: 45px;
    font-weight: 700;
    font-family: var(--main-font-family);
}
